import { BoxProps, Flex } from '@chakra-ui/react';
import isFunction from 'lodash/isFunction';
import dynamic from 'next/dynamic';
import { NextSeo, NextSeoProps } from 'next-seo';
import { Translate } from 'next-translate';
import useTranslation from 'next-translate/useTranslation';
import React, { PropsWithChildren } from 'react';

import Hero, { HeroProps } from 'components/shared/hero';
import { Breadcrumbs, PageComponent } from 'types';
import Footer from './footer';
import Header, { HeaderProps } from './header';

export interface SiteLayoutProps {
  title?: string;
  breadcrumbs?: Breadcrumbs | ((t: Translate) => Breadcrumbs);
  contentProps?: BoxProps;
  header?: HeaderProps;
  seo?: NextSeoProps | ((t: Translate) => NextSeoProps);
  skipTranslation?: boolean;
  hideHero?: boolean;
  hero?: Partial<HeroProps>;
  footer?: BoxProps;
}

const SiteAlert = dynamic(() => import('./site-alert'), {
  ssr: false,
});

function SiteLayout({
  children,
  title,
  breadcrumbs,
  header,
  contentProps = {},
  seo,
  skipTranslation = false,
  hideHero = false,
  hero,
  footer,
}: PropsWithChildren<SiteLayoutProps>) {
  const { t } = useTranslation('common');

  const alteredBreadcrumbs = isFunction(breadcrumbs)
    ? breadcrumbs(t)
    : skipTranslation
    ? breadcrumbs
    : breadcrumbs?.map((b) => ({
        ...b,
        name: t(`pages.${b.name}`),
      }));

  const seoProps = isFunction(seo) ? seo(t) : seo;
  const seoTitle = seoProps?.title || (title ? `${t(title)} | Hype Clothinga` : 'Hype Clothinga');

  return (
    <Flex direction="column" minH="100vh" h="100%">
      <NextSeo title={seoTitle} openGraph={{ title: seoTitle }} {...seoProps} />
      <Header {...header} />
      <Flex
        direction="column"
        flex={1}
        mt={{ base: '58px', lg: '88px' }}
        h="full"
        {...contentProps}
      >
        {!hideHero && (title || alteredBreadcrumbs?.length > 0) && (
          <Hero
            title={skipTranslation ? title : t(title)}
            breadcrumbs={alteredBreadcrumbs}
            {...hero}
          />
        )}
        {children}
      </Flex>
      <Footer {...footer} />
      <SiteAlert />
    </Flex>
  );
}

export const getLayout: PageComponent['getLayout'] = (page) => <SiteLayout>{page}</SiteLayout>;

export default SiteLayout;
