import { Container, ContainerProps } from '@chakra-ui/react';
import { FC } from 'react';

const PageContainer: FC<ContainerProps> = ({ children, ...props }) => {
  return (
    <Container maxW="container.3xl" px={8} {...props}>
      {children}
    </Container>
  );
};

export default PageContainer;
